import React from 'react';
import './Header.css';

function Header() {
    return (
        <header className="header">
            <div className="name"><span className='smallName'>I'm</span><br />Tawfik</div>         
        </header>
    );
}

export default Header;
